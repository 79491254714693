import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import device from 'utils/device'
import List from 'components/List'
import Reveal from 'components/Reveal'

export default function BenefitsCols() {
    const { t } = useTranslation('home')

    const items = [
        {
            color: 'accent',
            name: t('home_benefits_block_1_name'),
            list: t('home_benefits_block_1_items', {}, { returnObjects: true })
        },
        {
            color: 'purple',
            name: t('home_benefits_block_2_name'),
            list: t('home_benefits_block_2_items', {}, { returnObjects: true })
        }
    ]

    return (
        <Container>
            <Wrap>
                {items.map((item, index) => (
                    <Reveal key={index}>
                        <Block>
                            <h2 className="h2">{item.name}</h2>
                            {item.list.length && <List items={item.list.map(i => i.name)} color={item.color} />}
                        </Block>
                    </Reveal>
                ))}
            </Wrap>
        </Container>
    )
}

const Block = styled.div`
    background: #fff;
    border-radius: 0.24rem;
    width: 5.88rem;
    padding: 0.36rem;
    margin-bottom: 0.16rem;
    h2 {
        margin-bottom: 0.24rem;
    }
    ${device.mobile} {
        width: auto;
        padding: 0.24rem;
    }
`

const Wrap = styled.div`
    width: 12rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${device.mobile} {
        width: auto;
    }
`


const Container = styled.div`
    padding: 1.6rem 0 1.4rem 0;
    ${device.mobile} {
        padding: 0.8rem 0.16rem 0.54rem 0.16rem;
    }
`