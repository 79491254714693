import styled from 'styled-components'

export default function List({ items, color }) {
    return (
        <Container color={color}>
            {items.map((item, index) => (
                <li key={index}>
                    <span>{index + 1}</span>
                    {item}
                </li>
            ))}
        </Container>
    )
}

const Container = styled.ol`
    padding: 0;
    margin: 0;
    li {
        list-style: none;
        padding-left: 0.48rem;
        align-items: center;
        position: relative;
        display: flex;
        font-weight: 600;
        min-height: 0.36rem;
        margin-top: 0.12rem;
        span {
            user-select: none;
            align-items: center;
            justify-content: center;
            display: flex;
            position: absolute;
            width: 0.36rem;
            height: 0.36rem;
            background: var(${props => `--color-${props.color}`});
            border-radius: 50%;
            left: 0;
        }
    }
`
